import _ from 'lodash';
export class AreaAcreditacaoModel {
  constructor({
    id,
    nome,
    descricao,
  }) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
  }
  get request() {
    let retorno = _.cloneDeep(this);

    return retorno;
  }
}
